<template>
  <v-container id="admin-setting-view" fluid tag="section">
    <material-card color="primary" icon="mdi-account-outline">
      <template #title>
        {{ $t("settings.settingsList.breadcrumb.title") }}
      </template>
      <form-setting />
      <!-- <dialog-setting-form-password /> -->
      <dialog-setting-form-test-email />
    </material-card>
  </v-container>
</template>

<script>
import FormSetting from "../modules/admin/settings/components/FormSetting";
// import DialogSettingFormPassword from '../modules/admin/settings/components/DialogSettingFormPassword'
import DialogSettingFormTestEmail from "../modules/admin/settings/components/DialogSettingFormTestEmail";
export default {
  name: "AdminSetting",
  components: {
    FormSetting,
    // DialogSettingFormPassword,
    DialogSettingFormTestEmail,
  },
  metaInfo: {
    titleTemplate: "Admin Setting",
  },
};
</script>

<style>
</style>
