<template>
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="headline font-weight-black mx-n2">
        {{ $t('settings.settingsList.authentication.title') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="authenticationConfig.ClientIDGg" :rules="fieldRules" label="Sign in - Google Account"
            outlined dense />
          <v-text-field v-model="authenticationConfig.IDAPPFACEBOOK" :rules="fieldRules"
            label="Sign in - Facebook Account" outlined dense />
          <v-row>
            <v-col cols="4">
              <v-switch v-model="authenticationConfig.authEmail" color="primary" label="Account With Email" outlined
                dense />
            </v-col>
            <v-col cols="4">
              <v-switch v-model="authenticationConfig.authCodeEmail" :disabled="!authenticationConfig.authEmail"
                color="primary" label="Auth code Email" outlined dense />
            </v-col>
            <v-col cols="4">
              <v-select v-model="authenticationConfig.authCodeEmailTime" :items="authCodeTime" :disabled="!authenticationConfig.authEmail" item-text="text"
                item-value="value" label="Auth code Email Time" outlined dense />
            </v-col>
            <v-col cols="4">
              <v-switch v-model="authenticationConfig.authPhone" color="primary" label="Account With Phone" outlined
                dense />
            </v-col>
            <v-col cols="4">
              <v-switch v-model="authenticationConfig.authCodePhone" :disabled="!authenticationConfig.authPhone"
                color="primary" label="Auth code Phone" outlined dense />
            </v-col>
            <v-col cols="4">
              <v-select v-model="authenticationConfig.authCodePhoneTime" :items="authCodeTime" :disabled="!authenticationConfig.authPhone" item-text="text"
                item-value="value" label="Auth code Phone Time" outlined dense />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn color="info" :disabled="!valid" @click="save" v-text="$t('settings.settingsList.actions.save')" />
          </v-card-actions>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'
export default {
  name: 'SettingAuthentication',
  data: () => ({
    // check field error form
    valid: true,
    fieldRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
    authCodeTime: [
      {
        text: '30s',
        value: 30,
      },
      {
        text: '60s',
        value: 60,
      },
      {
        text: '2m',
        value: 120,
      },
      {
        text: '5m',
        value: 300,
      },
    ],
  }),
  computed: {
    ...sync('adminSetting', [
      'authenticationConfig',
    ]),
  },
  watch: {
    'authenticationConfig.authEmail'(newValue) {
      if (!newValue) {
        this.authenticationConfig.authCodeEmail = false;
      }
    },
    'authenticationConfig.authPhone'(newValue) {
      if (!newValue) {
        this.authenticationConfig.authCodePhone = false;
      }
    },
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate()
      if (!valueCheck) {
        return
      }
      if (!this.authenticationConfig.authEmail && !this.authenticationConfig.authPhone) {
        this.$toast.error('Vui lòng chọn ít nhất là email hay phone khi đăng nhập !!!')
        return;
      }
      const dataAdminSettingResult = await this.$store.dispatch('adminSetting/updateAuthenticationConfig', this.getTokenLogin())
      if (dataAdminSettingResult.status === false) {
        return
      }
      this.$toast.success(dataAdminSettingResult.data.message)
      this.$store.dispatch('adminSetting/fetchDataConfig', this.getTokenLogin())
    },
  },
}
</script>

<style scoped></style>
