
<template>
  <v-dialog v-if="isShowDialogSettingFormTestEmail" v-model="isShowDialogSettingFormTestEmail" max-width="400">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="primary--text font-weight-bold text-h5" v-text="$t('settings.settingsList.emailConfiguration.titleTestEmail')" />
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field v-model="emailConfig.toEmail" :rules="emailRules" label="To Email Test" outlined dense />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue-grey" @click="isShowDialogSettingFormTestEmail = false"
            v-text="$t('settings.settingsList.actions.cancel')" />
          <v-btn color="info" :disabled="!valid" @click="save" v-text="$t('settings.settingsList.actions.send')" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'
export default {
  name: 'DialogSettingFormTestEmail',
  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
  }),
  computed: {
    ...sync('adminSetting', [
      'isShowDialogSettingFormTestEmail',
      'emailConfig',
    ]),
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate()
      // if valueCheck = false then stop function save
      if (!valueCheck) {
        return
      }
      const dataAdminSettingResult = await this.$store.dispatch('adminSetting/sendTestEmailConfig', this.getTokenLogin())
      if (dataAdminSettingResult.status === false) {
        this.$toast.error('Gửi Email không thành công, vui lòng kiểm tra cấu hình Email !!! ')
        return
      }
      this.$toast.success('Gửi Email thành công, vui lòng kiểm tra hộp thư hoặc tin nhắn rác')
      this.isShowDialogSettingFormTestEmail = false
    },
  },
}
</script>

<style scoped></style>
