<template>
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="headline font-weight-black mx-n2">
        {{ $t('settings.settingsList.emailConfiguration.title') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="emailConfig.port" type="number" min="0" :rules="portRules" label="Port Email" outlined
            dense />
          <v-text-field v-model="emailConfig.email" :rules="emailRules" label="E-mail" outlined dense />
          <v-text-field v-model="emailConfig.password" outlined dense
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[passwordRules.required, passwordRules.min, passwordRules.max]"
            :type="showPassword ? 'text' : 'password'" label="Password Email"
            @click:append="showPassword = !showPassword" />
          <v-text-field v-model="emailConfig.hostName" :rules="fieldRules" label="Host Name" outlined dense />
          <v-text-field v-model="emailConfig.senderAddress" :rules="fieldRules" label="Sender Address" outlined dense />
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="!valid" color="error" @click="isShowDialogSettingFormTestEmail = true;"
              v-text="$t('settings.settingsList.actions.testEmail')" />
            <v-btn color="info" :disabled="!valid" @click="save" v-text="$t('settings.settingsList.actions.save')" />
          </v-card-actions>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'
export default {
  name: 'SettingEmailConfiguaration',
  data: () => ({
    // check error form email and password
    valid: true,
    portRules: [
      (v) => !!v || 'Port is required',
      (v) => v > 0 || 'Port must be greater than 0',
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
    showPassword: false,
    passwordRules: {
      required: (v) => !!v || 'Password is required',
      min: (v) => (v && v.length >= 8) || 'Min 8 characters',
      max: (v) => (v && v.length <= 255) || 'Max 255 characters',
    },
    fieldRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
  }),
  computed: {
    ...sync('adminSetting', [
      'emailConfig',
      'isShowDialogSettingFormTestEmail',
    ]),
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate()
      if (!valueCheck) {
        return
      }
      const dataAdminSettingResult = await this.$store.dispatch('adminSetting/updateEmailConfig', this.getTokenLogin())
      if (dataAdminSettingResult.status === false) {
        return
      }
      this.$toast.success(dataAdminSettingResult.data.message)
      this.$store.dispatch('adminSetting/fetchDataConfig', this.getTokenLogin())
    },
  },
}
</script>

<style scoped></style>
