<template>
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="headline font-weight-black mx-n2">
        Default Media
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form v-if="defaultMediaConfig" ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="defaultMediaConfig.mimeTypeImage"
            :rules="fieldRules"
            type="text"
            label="Mime Type Image"
            outlined
            dense
          />
          <v-row class="mb-2">
            <v-col>
              <v-text-field
                v-model="defaultMediaConfig.maxFileSizeImage"
                :rules="fieldRules"
                label="Max File Size Image"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    v-model="maxFileSizeImageUnit"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            v-model="defaultMediaConfig.mimeTypeVideo"
            :rules="fieldRules"
            type="text"
            label="Mime Type Video"
            outlined
            dense
          />
          <v-row class="mb-2">
            <v-col>
              <v-text-field
                v-model="defaultMediaConfig.maxFileSizeVideo"
                :rules="fieldRules"
                label="Max File Size Video"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    v-model="maxFileSizeVideoUnit"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-text-field
            v-model="defaultMediaConfig.mimeTypeDocument"
            :rules="fieldRules"
            type="text"
            label="Mime Type Document"
            outlined
            dense
          />
          <v-row>
            <v-col>
              <v-text-field
                v-model="defaultMediaConfig.maxFileSizeDocument"
                :rules="fieldRules"
                label="Max File Size Document"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    v-model="maxFileSizeDocumentUnit"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="defaultMediaConfig.maxTotalFileSizeMedia"
                :rules="fieldRules"
                label="Max Total File Size Media"
                type="number"
                outlined
                dense
                hide-details
              >
                <template v-slot:append>
                  <v-select
                    hide-details
                    class="pa-3 v-select_format"
                    underlined
                    :label="$t('packageMedia.packageMediaForm.form.unit')"
                    v-model="maxTotalFileSizeMediaUnit"
                    :items="['MB', 'GB']"
                  />
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn color="info" :disabled="!valid" @click="save" v-text="$t('settings.settingsList.actions.save')" />
          </v-card-actions>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'
export default {
  name: 'SettingDefaultMedia',
  data: () => ({
    valid: true,
    fieldRules: [
      (v) => !!v || 'Field is required',
      // && v < 999999999999 && v > 1
      // (v) => (v && (!Number.isInteger(v))) || 'Field is number Interger and Field must be greater than 1 and less than 999999999999 byte',
    ],
    maxFileSizeImageUnit: "",
    maxFileSizeVideoUnit: '',
    maxFileSizeDocumentUnit: '',
    maxTotalFileSizeMediaUnit: '',
  }),
  computed: {
    ...sync('adminSetting', [
      'defaultMediaConfig',
    ]),
  },
  created() {
    setTimeout(() => {
        this.formatValueToGbOrMb();
      }, 1000)
  },
  methods: {
    formatValueToGbOrMb() {
      this.maxFileSizeImageUnit = this.fileSize(this.defaultMediaConfig.maxFileSizeImage).unit;
      this.defaultMediaConfig.maxFileSizeImage = this.fileSize(this.defaultMediaConfig.maxFileSizeImage).res;

      this.maxFileSizeVideoUnit = this.fileSize(this.defaultMediaConfig.maxFileSizeVideo).unit;
      this.defaultMediaConfig.maxFileSizeVideo = this.fileSize(this.defaultMediaConfig.maxFileSizeVideo).res;

      this.maxFileSizeDocumentUnit = this.fileSize(this.defaultMediaConfig.maxFileSizeDocument).unit;
      this.defaultMediaConfig.maxFileSizeDocument = this.fileSize(this.defaultMediaConfig.maxFileSizeDocument).res;

      this.maxTotalFileSizeMediaUnit = this.fileSize(
        this.defaultMediaConfig.maxTotalFileSizeMedia
      ).unit;
      this.defaultMediaConfig.maxTotalFileSizeMedia = this.fileSize(
        this.defaultMediaConfig.maxTotalFileSizeMedia
      ).res;
    },
    formatValueToGbOrMbNoChange() {
      this.defaultMediaConfig.maxFileSizeImage = this.fileSizeNoChange(
        this.defaultMediaConfig.maxFileSizeImage,
        this.maxFileSizeImageUnit
      );

      this.defaultMediaConfig.maxFileSizeVideo = this.fileSizeNoChange(
        this.defaultMediaConfig.maxFileSizeVideo,
        this.maxFileSizeVideoUnit
      );


      this.defaultMediaConfig.maxFileSizeDocument = this.fileSizeNoChange(
        this.defaultMediaConfig.maxFileSizeDocument,
        this.maxFileSizeDocumentUnit
      );
    
      this.defaultMediaConfig.maxTotalFileSizeMedia = this.fileSizeNoChange(
        this.defaultMediaConfig.maxTotalFileSizeMedia,
        this.maxTotalFileSizeMediaUnit
      );
    },
    take_decimal_number(num = 0, n) {
      const base = 10 ** n;
      const result = Math.round(num * base) / base;
      return result;
    },
    fileSize(data) {
      if (!data) { return 0 }
      const convertMB = (data * 0.001) / 1024;
      let result = this.take_decimal_number(convertMB, 1);
      let unit = "MB";
      if (result > 1024 || result === 1024) {
        result = result / 1024;
        unit = "GB";
      }
      let res = result;
      if (res < 1) {
        res = 1;
      }
      return {
        res: res,
        unit: unit,
      };
    },
    fileSizeNoChange(data, unit) {
      const convertMB = (data * 0.001) / 1024;
      const result = "";
      if (unit === "MB") {
        this.result = this.take_decimal_number(convertMB, 1);
      }
      if (unit === "GB") {
        this.result = this.take_decimal_number(convertMB, 1);
        this.result = this.result / 1024;
      }
      let res = this.result;
      if (res < 1) {
        res = 1;
      }
      return res;
    },
    convertValueToKb(data, dataUnit) {
      let res = 0;
      if (data && dataUnit === "MB") {
        res = (data * 1024) / 0.001;
      } else {
        res = (data * 1048576) / 0.001;
      }
      return res;
    },
    async save() {
      this.defaultMediaConfig.maxFileSizeImage = this.convertValueToKb(
        this.defaultMediaConfig.maxFileSizeImage,
        this.maxFileSizeImageUnit
      );

      this.defaultMediaConfig.maxFileSizeDocument = this.convertValueToKb(
        this.defaultMediaConfig.maxFileSizeDocument,
        this.maxFileSizeDocumentUnit
      );

      this.defaultMediaConfig.maxFileSizeVideo = this.convertValueToKb(
        this.defaultMediaConfig.maxFileSizeVideo,
        this.maxFileSizeVideoUnit
      );
      
      this.defaultMediaConfig.maxTotalFileSizeMedia = this.convertValueToKb(
        this.defaultMediaConfig.maxTotalFileSizeMedia,
        this.maxTotalFileSizeMediaUnit
      );
      const valueCheck = this.$refs.form.validate()
      if (!valueCheck) {
        return
      }
      const dataAdminSettingResult = await this.$store.dispatch('adminSetting/updateDefaultMediaConfig', this.getTokenLogin())
      if (dataAdminSettingResult.status === false) {
        if (Array.isArray(dataAdminSettingResult?.data)) {
          dataAdminSettingResult.data.forEach((dataMessage) => {
            const keyAttribute = Object.keys(dataMessage)[0]
            const keyAttributeMessage = dataMessage[keyAttribute]?.message || 'Update Fail'
            this.$toast.error(`${keyAttribute} ${keyAttributeMessage}`)
          })
        } else {
          this.$toast.error(dataAdminSettingResult?.data?.message || 'Update Fail')
        }
        return
      }
      this.$toast.success(dataAdminSettingResult.data.message)
      setTimeout(() => {
        this.$store.dispatch('adminSetting/fetchDataConfig', this.getTokenLogin())
        setTimeout(() => {
          this.formatValueToGbOrMb();
        }, 500);
      }, 800)
    },
  },
}
</script>

<style scoped></style>
