<template>
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="headline font-weight-black mx-n2">
        {{ $t("settings.settingsList.generalSettings.title") }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="serverConfig.domainFE"
            :rules="fieldRules"
            label="Domain FE"
            outlined
            dense
          />
          <v-text-field
            v-model="serverConfig.domainBE"
            :rules="fieldRules"
            label="Domain BE"
            outlined
            dense
          />
          <v-text-field
            v-model="serverConfig.whiteList"
            :rules="fieldString"
            label="White List"
            outlined
            dense
          />
          <v-text-field
            v-model="serverConfig.blackList"
            :rules="fieldString"
            label="Black List"
            outlined
            dense
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="info"
              :disabled="!valid"
              @click="save"
              v-text="$t('settings.settingsList.actions.save')"
            />
          </v-card-actions>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// Vuex
import { sync } from "vuex-pathify";
export default {
  name: "SettingGeneral",
  data: () => ({
    // check field error form
    valid: true,
    fieldRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length <= 255) || "Max 255 characters",
    ],
    fieldString: [(v) => (v && v.length <= 255) || !v || "Max 255 characters"],
  }),
  computed: {
    ...sync("adminSetting", ["serverConfig"]),
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate();
      if (!valueCheck) {
        return;
      }
      const dataAdminSettingResult = await this.$store.dispatch(
        "adminSetting/updateServerConfig",
        this.getTokenLogin()
      );
      if (dataAdminSettingResult.status === false) {
        return;
      }
      this.$toast.success(dataAdminSettingResult.data.message);
      setTimeout(() => {
        this.$store.dispatch(
          "adminSetting/fetchDataConfig",
          this.getTokenLogin()
        );
      }, 800);
    },
  },
};
</script>

<style scoped></style>
