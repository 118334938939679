<template>
  <v-card
    class="mb-2"
  >
    <v-card-title>
      <span
        class="headline font-weight-black font-weight-black"
        v-text="$t('settings.settingsList.version.title')"
      />
    </v-card-title>
    <v-card style="border-top-right-radius: 0px;  border-top-left-radius: 0px;">
      <v-container class="ml-4">
        <template>
          <label
            class="mr-2 font-weight-medium"
            for="appName"
            v-text="$t('settings.settingsList.version.name')"
          />
          <span class="text-justify">
            Web Shoes
          </span>
        </template>
      </v-container>
      <v-container class="ml-4">
        <template>
          <label
            class="mr-2 font-weight-medium"
            for="releaseDate"
            v-text="$t('settings.settingsList.version.date')"
          />
          <span class="text-justify">
            06-12-2021
          </span>
        </template>
      </v-container>
      <v-container class="ml-4">
        <template>
          <label
            class="mr-2 font-weight-medium"
            for="featureUpdates"
          >Feature Updates:</label>
          <span class="text-justify">
            V1
          </span>
        </template>
      </v-container>
    </v-card>
  </v-card>
  <!-- version -->
</template>

<script>
  export default {
    name: 'Version',
  }
</script>

<style>

</style>
