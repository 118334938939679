<template>
  <v-card-text>
    <template>
      <version />
      <api />
      <email />
      <!-- <change-password /> -->
      <setting-authentication />
      <setting-email-configuaration />
      <setting-general />
      <setting-minio />
      <setting-default-media />
    </template>
  </v-card-text>
</template>

<script>
import Version from "./Version";
import Api from "./Api";
import Email from "./Email";
// import ChangePassword from './ChangePassword'
import SettingAuthentication from "./SettingAuthentication";
import SettingEmailConfiguaration from "./SettingEmailConfiguaration";
import SettingGeneral from "./SettingGeneral";
import SettingMinio from "./SettingMinio";
import SettingDefaultMedia from "./SettingDefaultMedia";
export default {
  name: "FormSetting",
  components: {
    Version,
    Api,
    Email,
    // ChangePassword,
    SettingAuthentication,
    SettingEmailConfiguaration,
    SettingGeneral,
    SettingMinio,
    SettingDefaultMedia,
  },
  async created() {
    // call API fetch data config from Store
    await this.$store.dispatch(
      "adminSetting/fetchDataConfig",
      this.getTokenLogin()
    );
  },
};
</script>

<style scoped>
</style>
