<template>
  <v-card
    class="mb-2"
  >
    <v-card-title>
      <span
        class="headline font-weight-black"
        v-text="$t('settings.settingsList.api.title')"
      />
    </v-card-title>
    <v-card style="border-top-right-radius: 0px;  border-top-left-radius: 0px;">
      <v-container class="ml-4">
        <template>
          <label
            class="mr-2 font-weight-medium"
            for="appAPI"
          >App API:</label>
          <div
            style="display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;"
          >
            <div style="width:60%">
              <span class="text-justify">
                {{ flagAppAPI ? handleHash(appAPI) : appAPI }}
              </span>
            </div>
            <div style="width:40%;display: flex; justify-content: center;">
              <v-icon
                style="cursor:pointer;color: blue; padding: 0 5px;"
                @click="()=>{flagAppAPI=!flagAppAPI}"
              >
                {{ flagAppAPI ? iconShow : iconHide }}
              </v-icon>
              <v-btn
                color="success"
                class="mr-1"
                v-text="$t('settings.settingsList.actions.delete')"
              />
              <v-btn
                color="error"
                class="ml-1"
                v-text="$t('settings.settingsList.actions.reset')"
              />
            </div>
          </div>
        </template>
      </v-container>
      <v-container class="ml-4">
        <template>
          <label
            class="mr-2 font-weight-medium"
            for="apiPublicKey"
          >API Public Key:</label>
          <div
            style="display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;"
          >
            <div style="width:60%">
              <span class="text-justify">
                {{ flagPublicKey ? handleHash(apiPublicKey): apiPublicKey }}
              </span>
            </div>
            <div style="width: 40%;display: flex; justify-content: center;">
              <v-icon
                style="cursor:pointer;color: blue;padding: 0 5px;"
                @click="()=>{flagPublicKey=!flagPublicKey}"
              >
                {{ flagPublicKey ? iconShow : iconHide }}
              </v-icon>
              <v-btn
                color="success"
                class="mr-1"
                v-text="$t('settings.settingsList.actions.delete')"
              />
              <v-btn
                color="error"
                class="ml-1"
                v-text="$t('settings.settingsList.actions.reset')"
              />
            </div>
          </div>
        </template>
      </v-container>
      <v-container>
        <template>
          <v-card style="width:100%;padding: 10px 16px;">
            <label
              class="mr-2 font-weight-medium p"
              for="apiSecretKey"
            >API Secret Key:</label><br>
            <div
              style="display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: -3px;"
            >
              <div style="width: 60%;">
                <span class="text-justify">
                  {{ flagSecretKey ? handleHash(apiSecretKey) : apiSecretKey }}
                </span>
              </div>
              <div style="width: 37%; display: flex; justify-content: center;">
                <v-icon
                  style="cursor:pointer;color: blue;padding: 0 5px;"
                  @click="()=>{flagSecretKey=!flagSecretKey}"
                >
                  {{ flagSecretKey ? iconShow : iconHide }}
                </v-icon>
                <v-btn
                  color="success"
                  class="mr-1"
                  v-text="$t('settings.settingsList.actions.delete')"
                />
                <v-btn
                  color="error"
                  class="ml-1"
                  v-text="$t('settings.settingsList.actions.reset')"
                />
              </div>
            </div>
          </v-card>
        </template>
      </v-container>
    </v-card>
  </v-card>
  <!-- version -->
</template>

<script>
  import { mdiEye, mdiEyeOff } from '@mdi/js'
  export default {
    name: 'Api',
    data () {
      return {
        iconShow: mdiEye,
        iconHide: mdiEyeOff,
        appAPI: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjoiZ20zNTdIY0JFeEVvWEFvR1FhQVMiLCJlbWFpbCI6InJvb3RAdHQuY29tIiwicm9sZSI6IlJvb3QiLCJpYXQiOjE2MjMxNDA5NzksImV4cCI6MTYyNTczMjk3OX0.FDft58A2vDolbBQIoNSOUyGUZFZ8pTtjRu7cRb3SHbA',
        flagAppAPI: true,
        hashAppAPI: null,
        apiPublicKey: 'eyJhY2NvdW50X2lkIjoiZ20zNTdIY0JFeEVvWEFvR1FhQVMiLCJlbWFpbCI6InJvb3RAdHQuY29tIiwicm9sZSI6IlJvb3QiLCJpYXQiOjE2MjMxNDA5NzksImV4cCI6MTYyNTczMjk3OX0',
        flagPublicKey: true,
        hashPublicKey: '',
        apiSecretKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9FDft58A2vDolbBQIoNSOUyGUZFZ8pTtjRu7cRb3SHbA',
        flagSecretKey: true,
        hashSecretKey: '',
      }
    },
    methods: {
      handleHash (value) {
        let arr = ''
        for (let i = 0; i < value.length; i++) {
          arr = arr + '*'
        }
        return arr
      },
    },
  }

</script>

<style>
  .container{
    width: auto;
  }
  .v-form{
    padding: 10px 0;
  }
</style>
