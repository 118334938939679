<template>
  <v-card class="mb-2">
    <v-card-title>
      <span class="headline font-weight-black" v-text="$t('settings.settingsList.email.title')" />
    </v-card-title>
    <v-card class="px-9">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field v-model="emailServer" label="Email Server" :readonly="flagEdit" :rules="emailRules" />
        <v-text-field v-model="userName" :label="$t('settings.settingsList.email.userName')" :readonly="flagEdit"
          :rules="userNameRules" />
      </v-form>
    </v-card>
    <v-card-actions>
      <v-spacer />
      <!-- <v-btn
        v-if="flagEdit"
        color="error"
        @click="flagEdit=false"
        v-text="$t('settings.settingsList.actions.edit')"
      />
      <v-btn
        v-if="!flagEdit"
        color="blue-grey"
        @click="flagEdit=true"
        v-text="$t('settings.settingsList.actions.close')"
      />
      <v-btn
        v-if="!flagEdit"
        color="info"
        :disabled="!valid"
        @click="save"
        v-text="$t('settings.settingsList.actions.save')"
      /> -->
    </v-card-actions>
  </v-card>
  <!-- version -->
</template>

<script>
export default {
  name: 'Email',
  data() {
    return {
      flagEdit: false,
      // check port
      port: 4000,
      portRules: [
        (v) => !!v || 'Port is required',
        (v) => v > 0 || 'Port must be greater than 0',
      ],
      // check userName
      userName: 'root',
      userNameRules: [
        (v) => !!v || 'Port is required',
        (v) => (/^[a-z0-9]+$/i).test(v) || 'UserName  has only characters and numbers',
      ],
      // error form email and password
      // check email
      emailServer: this.getEmailLogin(),
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        (v) => (v && v.length <= 255) || 'Max 255 characters',
      ],
      valid: true,
      // check password
      password: '4masBb2vegdQGMds',
      showPassword: false,
      passwordRules: {
        required: (v) => !!v || 'Password is required',
        min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        max: (v) => (v && v.length <= 255) || 'Max 255 characters',
      },
    }
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate()
      // if valueCheck = false then stop function save
      if (!valueCheck) {
        return
      }
      this.flagEdit = true
    },
  },
}
</script>

<style></style>
