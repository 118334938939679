<template>
  <v-expansion-panels class="mt-2">
    <v-expansion-panel>
      <v-expansion-panel-header class="headline font-weight-black mx-n2">
        {{ $t('settings.settingsList.minioSetting.title') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field v-model="minioConfig.key" :rules="fieldRules" label="Key" outlined dense />
          <v-text-field v-model="minioConfig.secret" :rules="fieldRules" label="Secret" outlined dense />
          <v-text-field v-model="minioConfig.region" :rules="fieldRules" label="Region" outlined dense />
          <v-text-field v-model="minioConfig.prefixBucket" :rules="fieldRules" label="Prefix Bucket" outlined dense />
          <v-text-field v-model="minioConfig.endpoint" :rules="fieldRules" label="Endpoint" outlined dense />
          <v-card-actions>
            <v-spacer />
            <v-btn color="info" :disabled="!valid" @click="save" v-text="$t('settings.settingsList.actions.save')" />
          </v-card-actions>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
// Vuex
import { sync } from 'vuex-pathify'
export default {
  name: 'SettingMinio',
  data: () => ({
    valid: true,
    fieldRules: [
      (v) => !!v || 'Field is required',
      (v) => (v && v.length <= 255) || 'Max 255 characters',
    ],
  }),
  computed: {
    ...sync('adminSetting', [
      'minioConfig',
    ]),
  },
  methods: {
    async save() {
      const valueCheck = this.$refs.form.validate()
      if (!valueCheck) {
        return
      }
      const dataAdminSettingResult = await this.$store.dispatch('adminSetting/updateMinioConfig', this.getTokenLogin())
      if (dataAdminSettingResult.status === false) {
        this.$toast.error(dataAdminSettingResult?.data?.message || 'Update fail')
        return
      }
      this.$toast.success(dataAdminSettingResult.data.message)
      setTimeout(() => {
        this.$store.dispatch('adminSetting/fetchDataConfig', this.getTokenLogin())
      }, 800)
    },
  },
}
</script>

<style scoped></style>
